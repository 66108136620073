import {Popover} from '@mui/material';
import {
  Checkbox,
  CheckboxSize,
  DropdownListSize,
  DropdownListWidth,
  Icon,
  IconColor,
  IconSize,
  IconSvg,
  TagColor,
  Tooltip,
  TooltipPosition,
} from '@symfonia/brandbook';
import clsx from 'clsx';
import {isEqual} from 'lodash';
import {
  ChangeEvent,
  Fragment,
  MouseEvent,
  PropsWithChildren,
  ReactElement,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import {Tag, TagSize} from '../Tag/Tag';

export type DropdownListOption<TValue = any> = {
  label: string;
  value: TValue;
  href?: string;
  group?: string;
  tag?: {
    color?: TagColor;
    text?: string;
    size?: TagSize;
  };
  lIcon?: IconSvg | null;
};

export type anchorOriginType = {
  vertical: number | 'center' | 'top' | 'bottom',
  horizontal: number | 'center' | 'left' | 'right',
}

export type DropdownListProps<TOption = any> = {
  options: Map<React.Key, DropdownListOption<TOption>>;
  value?: Set<React.Key>;
  onChange?: (selectedValues: React.Key[]) => void;
  onClose?: () => void;
  isOpen?: boolean;
  anchorEl?: RefObject<HTMLElement> | null;
  className?: string | undefined;
  width?: DropdownListWidth;
  size?: DropdownListSize;
  multiple?: boolean;
  selectable?: boolean;
  useSearch?: boolean;
  defaultOpenGroups?: string[];
  listWithTooltip?: boolean;
  anchorOrigin?: anchorOriginType;
  transformOrigin?: anchorOriginType;
  tooltipDisabled?: boolean;
  testId?: string;
};

export const DropdownList = <TOption, >({
                                          options,
                                          value = new Set(),
                                          onChange = () => undefined,
                                          onClose = () => undefined,
                                          isOpen = false,
                                          anchorEl = null,
                                          className = undefined,
                                          width = DropdownListWidth.AS_ANCHOR,
                                          size = DropdownListSize.MD,
                                          multiple = false,
                                          selectable = true,
                                          useSearch = false,
                                          children = undefined,
                                          defaultOpenGroups = [],
                                          listWithTooltip = false,
                                          anchorOrigin,
                                          transformOrigin,
                                          tooltipDisabled,
                                          testId,
                                        }: PropsWithChildren<DropdownListProps<TOption>>) => {
  const [searchPhrase, setSearchPhrase] = useState<string | null>(null);
  const [isMouse, setIsMouse] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<Set<React.Key>>(value);
  const selectedValuesEntries = Array.from(selectedValues.keys());
  const valueRef = useRef(value);

  const optionValues = Array.from(options.values());
  const optionKeys = Array.from(options.keys());
  const optionEntries = Array.from(options.entries());

  const [openGroups, _setOpenGroups] = useState(defaultOpenGroups);
  const setOpenGroup = (name: string, nextIsOpen: boolean) => {
    _setOpenGroups(() => (nextIsOpen ? [...openGroups, name] : openGroups.filter(s => s !== name)));
  };
  const isOpenGroup = (name: string) => openGroups.includes(name);

  useEffect(() => {
    if (!isEqual(valueRef.current, value)) {
      setSelectedValues(value);
      valueRef.current = value;
    }
  }, [value]);

  const isOptionSelected = (optionKey: React.Key): boolean => selectedValues.has(optionKey);

  const isOptionDisabled = (optionKey: React.Key): boolean => {
    return !multiple && isOptionSelected(optionKey);
  };

  const handleOptionSelect = (optionKey: React.Key) => {
    if (isOptionDisabled(optionKey)) {
      return;
    }

    let nextSelectedValues: React.Key[];
    if (!multiple) {
      nextSelectedValues = [optionKey];
    } else {
      nextSelectedValues = selectedValues.has(optionKey)
        ? selectedValuesEntries.filter(v => v !== optionKey)
        : [...selectedValuesEntries, optionKey];
    }

    if (selectable) {
      setSelectedValues(new Set(nextSelectedValues));
    }

    onChange(nextSelectedValues);
  };

  const handleGroupSelect = (valuesInGroup: React.Key[], checked: boolean): void => {
    const nextSelectedValues = checked
      ? [...selectedValuesEntries.filter(_value => !valuesInGroup.includes(_value)), ...valuesInGroup]
      : [...selectedValuesEntries.filter(_value => !valuesInGroup.includes(_value))];

    if (selectable) {
      setSelectedValues(new Set(nextSelectedValues));
    }

    onChange(nextSelectedValues);
  };

  const handleOptionKeyDown = (keyCode: string, optionKey: React.Key, index: number) => {
    if (keyCode === 'Enter' || keyCode === 'Space') {
      handleOptionSelect(optionKey);
    }
  };

  const startsAt = (str: string, subStr: string): number => str.toLowerCase().indexOf(subStr.toLowerCase());

  const getSlices = (str: string, subStr: string): string[] => {
    const startAt = startsAt(str, subStr);
    if (startAt !== -1) {
      return [str.slice(0, startAt), str.slice(startAt, startAt + subStr.length), str.slice(startAt + subStr.length)];
    }
    return [str];
  };

  const getIcon = (icon: IconSvg): ReactElement => {
    return <Icon svg={icon} size={IconSize.MD} className={'mr-[10px]'}/>;
  };
  const getLabel = (str: string, subStr = ''): ReactElement => {
    if (!searchPhrase || searchPhrase.length === 0) {
      return <div className="pointer-events-none line-clamp-1 overflow-hidden text-ellipsis">{str}</div>;
    }
    const slices = getSlices(str, searchPhrase);
    if (slices.length === 1) {
      return <div className="pointer-events-none line-clamp-1 overflow-hidden text-ellipsis">{str}</div>;
    }

    return (
      <span className="pointer-events-none whitespace-pre-wrap">
        {slices[0]}
        <span className="bg-primary-100 group-hover:bg-transparent">{slices[1]}</span>
        {slices[2]}
      </span>
    );
  };

  const renderGroupCheckbox = (optionIndex: number): ReactElement => {
    const groupName = optionValues[optionIndex]?.group;
    const optionsInGroup = optionEntries.filter(([_, value]) => value?.group === groupName);
    const keysInGroup = optionsInGroup.map(([key, _]) => key);
    const checkedOptionsInGroup = optionsInGroup.filter(([key, _]) => selectedValues.has(key));

    if (groupName === undefined || optionValues.findIndex(option => option.group === groupName) < optionIndex) {
      return <></>;
    }
    return (
      <div
        onKeyDown={event => {
          /** sic */
        }}
        key={groupName}
        className={clsx(
          'group hover:bg-primary-500 hover:text-white cursor-pointer px-[16px] shrink-0 flex items-center relative text-black',
          {
            'h-[40px]': size === DropdownListSize.SM,
            'h-[48px]': size === DropdownListSize.MD,
          },
        )}
      >
        <div
          className={clsx('flex w-full h-full items-center', {
            'border-b-grey-300 border-b-solid border-b': optionIndex < options.size - 1,
          })}
        >
          <Icon
            svg={isOpenGroup(groupName) ? IconSvg.KEYBOARD_ARROW_UP : IconSvg.KEYBOARD_ARROW_DOWN}
            size={IconSize.LG}
            className="shrink-0 mr-[8px] filter-grey-900 group-hover:filter-grey-0"
          />
          <div
            tabIndex={0}
            onClick={() => {
              setOpenGroup(groupName, !isOpenGroup(groupName));
            }}
            className={clsx(
              'w-full h-full flex items-center appearance-none focus:outline-none group-hover:border-b-primary-400 before:absolute before:left-[8px] before:w-[calc(100%-16px)] before:rounded-lg',
              {
                'before:h-[24px]': size === DropdownListSize.SM,
                'before:h-[32px]': size === DropdownListSize.MD,
              },
            )}
          >
            <Checkbox
              size={CheckboxSize.SM}
              checked={checkedOptionsInGroup.length === optionsInGroup.length}
              moderate={checkedOptionsInGroup.length > 0 && checkedOptionsInGroup.length < optionsInGroup.length}
              value={groupName}
              onChange={(checked: boolean, event) => {
                (event as MouseEvent<HTMLInputElement>)?.nativeEvent?.stopPropagation();
                handleGroupSelect(keysInGroup, checked);
              }}
              className="mr-[8px] shrink-0"
            />
            {groupName}
          </div>
        </div>
      </div>
    );
  };

  const renderOption = (optionKey: React.Key, optionValue: DropdownListOption<TOption>, index: number) => {
    const Component = !multiple && optionValue?.href ? 'a' : 'span';

    if (optionValue?.group !== undefined && isOpenGroup(optionValue?.group) === false) {
      return <></>;
    }

    const optionsArray = [...options];

    const tag = optionValue?.tag;

    const content = <div
      onKeyDown={event => handleOptionKeyDown(event.code, optionKey, index)}
      key={optionKey}
      className={clsx('pr-[16px] group shrink-0 flex items-center relative text-black', {
        'pl-[16px]': optionValue?.group === undefined,
        'pl-[48px]': optionValue?.group !== undefined,
        'hover:bg-primary-500 hover:text-white cursor-pointer': !isOptionDisabled(optionKey),
        'h-[40px]': size === DropdownListSize.SM,
        'h-[48px]': size === DropdownListSize.MD,
      })}
      data-testid={`dropdownChild-${optionValue.value}`}
    >
      <Component
        href={optionValue?.href}
        tabIndex={isOptionDisabled(optionKey) ? -1 : 0}
        title={!listWithTooltip ? optionValue?.label : undefined}
        onClick={() => handleOptionSelect(optionKey)}
        className={clsx({
          'text-grey-300': isOptionDisabled(optionKey),
          'border-b-grey-300 border-b-solid border-b': index < optionsArray.length - 1,
          'w-full h-full flex justify-between items-center appearance-none focus:outline-none group-hover:border-b-primary-400':
            true,
          'before:absolute before:left-[8px] before:w-[calc(100%-16px)] before:rounded-lg': true,
          'focus:before:outline focus:before:outline-2 focus:before:outline-yellow-500':
            (!isOptionDisabled(optionKey) || multiple) && !isMouse,
          'before:h-[24px]': size === DropdownListSize.SM,
          'before:h-[32px]': size === DropdownListSize.MD,
        })}
      >
            <span
              title={listWithTooltip ? optionValue?.label : undefined}
              className="inline-flex items-center"
            >
              {multiple && (
                <Checkbox
                  size={CheckboxSize.SM}
                  checked={isOptionSelected(optionKey)}
                  value={optionValue?.label}
                  onChange={(_next, event) => {
                    (event as MouseEvent<HTMLInputElement>).stopPropagation();
                  }}
                  className="mr-[8px] shrink-0"
                />
              )}
              {optionValue?.lIcon && getIcon(optionValue?.lIcon)}
              {getLabel(optionValue?.label)}
            </span>
        {tag?.text && <Tag text={tag.text} color={tag.color} size={tag.size}/>}
      </Component>
    </div>;

    return tooltipDisabled ? content :
      <Tooltip position={TooltipPosition.RIGHT} text={listWithTooltip && optionValue?.label}>
        {content}
      </Tooltip>;

  };

  const render = () => (
    <div
      onMouseDown={() => setIsMouse(true)}
      onKeyDown={() => setIsMouse(false)}
      className={clsx(className, {
        'flex bg-white border rounded-lg font-quicksand flex-col border-grey-300 shadow overflow-x-hidden': true,
        'w-full': width === DropdownListWidth.FULL,
        'w-[285px]': width === DropdownListWidth.AS_ANCHOR,
        'w-fit': width === DropdownListWidth.FIT,
      })}
      data-testid={testId}
    >
      {useSearch && (
        <div className="flex w-full h-[48px] shrink-0 px-[16px] items-center sticky top-0 bg-white z-[1]">
          <input
            autoFocus
            value={searchPhrase || ''}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchPhrase(event.target.value || '')}
            placeholder="Wyszukaj ..."
            className="appearance-none outline-none w-full"
          />
          <Icon className="grow-0 shrink-0 justify-self-end" color={IconColor.PRIMARY_500} svg={IconSvg.SEARCH}/>
        </div>
      )}

      {Array.from(options.entries())
        .filter(
          ([_, option]) => !searchPhrase || searchPhrase.length === 0 || startsAt(option.label, searchPhrase) !== -1,
        )
        .map(([key, option], index) => {
          return (
            <Fragment key={key}>
              {renderGroupCheckbox(index)}
              {renderOption(key, option, index)}
            </Fragment>
          );
        })}
      {children && (
        <div className="w-full flex h-[48px] shrink-0 items-center justify-center sticky bottom-0 bg-white z-[1]">
          {children}
        </div>
      )}
    </div>
  );

  const renderPopOver = () => {
    const popoverWidth = (() => {
      if (width === DropdownListWidth.FULL) {
        return '100%';
      }
      if (width === DropdownListWidth.AS_ANCHOR) {
        return '285px';
      }
      return 'fit-content';
    })();

    return (
      <Popover
        open={!!anchorEl && isOpen}
        onClose={onClose}
        anchorEl={anchorEl?.current}
        anchorOrigin={anchorOrigin !== undefined ? anchorOrigin : {
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={transformOrigin !== undefined ? transformOrigin : {
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: popoverWidth,
            background: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        {render()}
      </Popover>
    );
  };

  return <>{anchorEl ? renderPopOver() : render()}</>;
};
