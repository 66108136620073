import {isEqual} from 'lodash';

export type PositionModel = {
  top: number;
  left: number;
  right: number;
  bottom: number;
};

export type PositionViewportModel = {
  top: number;
  left: number;
  width: number;
  height: number;
};

const getAncestors = (element: HTMLElement, relation: 'offsetParent' | 'parentElement'): HTMLElement[] => {
  const ancestors: HTMLElement[] = [];
  let ancestor: HTMLElement | null = element;

  if (element[relation]) {
    do {
      ancestor = ancestor[relation] as HTMLElement;
      if (ancestor !== null) {
        ancestors.push(ancestor);
      }
    } while (ancestor !== null);
  }
  return ancestors;
};

export const getOffsetElementPath = (element: HTMLElement): HTMLElement[] => {
  return getAncestors(element, 'offsetParent');
};

export const getElementPath = (element: HTMLElement): HTMLElement[] => {
  return getAncestors(element, 'parentElement');
};

export const getWPos = () => ({
  top: 0,
  left: 0,
  width: window.innerWidth,
  height: window.innerHeight,
});

export const getVPos = (element: HTMLElement): PositionViewportModel => {
  if (element === null) {
    return getWPos();
  }

  let ancestors = getElementPath(element);

  const firstFixed = ancestors.findIndex(ancestor => getComputedStyle(ancestor).position === 'fixed');
  if (firstFixed !== -1) {
    ancestors = ancestors.slice(0, firstFixed + 1);
  }

  const output = ancestors.reduce<PositionModel>((acc: PositionModel, curr: HTMLElement): PositionModel => {
    if (getComputedStyle(curr).overflow === 'visible') {
      return acc;
    }

    const currRect = curr.getBoundingClientRect();

    return {
      top: Math.max(acc.top, currRect.top),
      right: Math.min(acc.right, currRect.right),
      bottom: Math.min(acc.bottom, currRect.bottom),
      left: Math.max(acc.left, currRect.left),
    };
  }, element.getBoundingClientRect());

  return {
    top: output.top,
    left: output.left,
    width: output.right - output.left,
    height: output.bottom - output.top,
  };
};

type RectObserverHandle = {
  ro: ResizeObserver;
  mo: MutationObserver;
  ancestors: HTMLElement[];
  rect: DOMRect;
  scrollFn: () => void;
};
export class RectObserver {
  private handles = new Map<HTMLElement, RectObserverHandle>();

  // eslint-disable-next-line no-useless-constructor
  public constructor(private callback: (nextRect: DOMRect, prevRect: DOMRect) => void) {
    /* SIC */
  }

  // eslint-disable-next-line class-methods-use-this
  public observe(el: HTMLElement) {
    const checkRect = () => {
      const handle = this.handles.get(el) as RectObserverHandle;
      const nextRect = el.getBoundingClientRect();
      if (!isEqual(handle.rect, nextRect)) {
        this.callback(nextRect, handle.rect);
        handle.rect = nextRect;
      }
    };

    const ancestors = getElementPath(el);
    const ro = new ResizeObserver(checkRect);
    const mo = new MutationObserver(checkRect);

    ro.observe(el);
    mo.observe(el, {
      attributes: true,
      attributeFilter: ['style', 'class'],
    });

    ancestors.forEach(ancestor => {
      ancestor.addEventListener('scroll', checkRect);
      ro.observe(ancestor);
      mo.observe(ancestor, {
        attributes: true,
        attributeFilter: ['style', 'class'],
      });
    });

    this.handles.set(el, {
      ro,
      mo,
      ancestors,
      rect: el.getBoundingClientRect(),
      scrollFn: checkRect,
    });
  }

  public unobserve(el: HTMLElement) {
    if (this.handles.has(el)) {
      const handle = this.handles.get(el) as RectObserverHandle;
      handle.ro.disconnect();
      handle.mo.disconnect();
      handle.ancestors.forEach(ancestor => ancestor.removeEventListener('scroll', handle.scrollFn));
      this.handles.delete(el);
    }
  }

  public disconnect() {
    this.handles.forEach((_value, el) => this.unobserve(el));
  }
}
