import React, {
  Children,
  cloneElement,
  FC,
  isValidElement,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {twMerge} from 'tailwind-merge';
import clsx from 'clsx';
import {Portal, PortalProps} from '../../external/components/Portal/Portal';
import {Level, LevelContext} from '../../external/context/LevelContext';

const isMouseOver = (elements: HTMLElement[], event: MouseEvent): boolean => {
  return elements.some(element => {
    const rect = element.getBoundingClientRect();
    return (
      event.clientX >= rect.left &&
      event.clientX <= rect.left + rect.width &&
      event.clientY >= rect.top &&
      event.clientY <= rect.top + rect.height
    );
  });
};

const Arrow: FC<{placement: 'top' | 'center' | 'bottom'}> = ({placement}) => {
  if (placement === 'top') {
    return <div className="arrow-up relative -top-[20px] rotate-180" />;
  }
  if (placement === 'bottom') {
    return <div className="arrow-up relative bottom-[3px]" />;
  }
  return <></>;
};

export type TooltipProps = {
  text?: ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
  /** Usually tooltip works on :hover, however certain state can be forced
   * by setting this flag */
  open?: boolean;
  tooltipClass?: string;
  position?: TooltipPosition;
  arrowClass?: string;
  testId?: string;
};

export enum TooltipPosition {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
}

const positionToOrigin = (
  position: TooltipPosition | undefined,
): Pick<PortalProps, 'anchorOrigin' | 'transformOrigin'> => {
  if (position === TooltipPosition.TOP) {
    return {
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'top',
      },
      transformOrigin: {
        horizontal: 'center',
        vertical: 'bottom',
      },
    };
  }
  if (position === TooltipPosition.RIGHT) {
    return {
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'center',
      },
      transformOrigin: {
        horizontal: 'left',
        vertical: 'center',
      },
    };
  }
  if (position === TooltipPosition.BOTTOM) {
    return {
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'bottom',
      },
      transformOrigin: {
        horizontal: 'center',
        vertical: 'top',
      },
    };
  }
  if (position === TooltipPosition.LEFT) {
    return {
      anchorOrigin: {
        horizontal: 'left',
        vertical: 'center',
      },
      transformOrigin: {
        horizontal: 'right',
        vertical: 'center',
      },
    };
  }
  return {
    anchorOrigin: {
      horizontal: 'center',
    },
    transformOrigin: {
      horizontal: 'center',
    },
  };
};

export const Tooltip: FC<PropsWithChildren<TooltipProps>> = ({
  text = '',
  position = undefined,
  onOpen = () => undefined,
  onClose = () => undefined,
  open = undefined,
  children = undefined,
  tooltipClass = undefined,
  arrowClass = undefined,
  testId = 'tooltip',
}) => {
  const isReactElement = isValidElement(text);
  const level = useContext(LevelContext);
  const portalRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLElement>();
  const child = Children.only(children) as ReactElement;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const childRef = child.ref as RefObject<HTMLElement>;
  const hasRef = childRef !== null;
  const output = useMemo(() => (hasRef ? child : cloneElement(child, {ref})), [children]);
  const [isOpen, _setIsOpen] = useState(open || false);
  const setIsOpen = (b: boolean) => {
    _setIsOpen(b);
    if (b && onOpen) {
      onOpen();
    }
    if (!b && onClose) {
      onClose();
    }
  };

  const hideTooltipRef = useRef<(mouseEvent: MouseEvent) => void>();

  useEffect(() => {
    if (open !== undefined) {
      setIsOpen(open);
    }
  }, [open]);

  useEffect(() => {
    const anchorEl = (childRef || ref).current as HTMLElement;

    hideTooltipRef.current = (mouseEvent: MouseEvent) => {
      let latestMouseEvent: MouseEvent = mouseEvent;
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const mouseMoveCapture = (mouseEvent: MouseEvent) => {
        latestMouseEvent = mouseEvent;
      };

      document.addEventListener('mousemove', mouseMoveCapture, true);

      if (isReactElement) {
        setTimeout(() => {
          document.removeEventListener('mousemove', mouseMoveCapture, true);
          const portalEl = portalRef.current?.parentElement as HTMLElement;
          const elements = [anchorEl, portalEl];

          if (
            !isMouseOver(
              elements.filter(x => !!x),
              latestMouseEvent,
            )
          ) {
            setIsOpen(false);
          }
        }, 120);
      } else {
        setIsOpen(false);
      }
    };

    const hideTooltipEagerly = () => setIsOpen(false);

    const showTooltip = () => {
      setIsOpen(true);
    };

    if (anchorEl && open === undefined) {
      anchorEl.addEventListener('mouseenter', showTooltip, true);
      anchorEl.addEventListener('mouseleave', hideTooltipRef.current, true);
      anchorEl.addEventListener('mousedown', hideTooltipEagerly, true);
    }
    return () => {
      if (anchorEl && open === undefined) {
        anchorEl.removeEventListener('mouseenter', showTooltip, true);
        anchorEl.removeEventListener('mouseleave', hideTooltipRef.current as (mouseEvent: MouseEvent) => void, true);
        anchorEl.removeEventListener('mousedown', hideTooltipEagerly, true);
      }
    };
  }, []);

  const renderContent = () => (
    <div
      data-testid={testId}
      className={twMerge(
        clsx(
          'top-[16px] flex max-w-[90vw] items-center m-[12px] w-max px-[16px] py-[8px] bg-black text-white rounded-[4px]',
          tooltipClass || '',
        ),
      )}
    >
      {text}
    </div>
  );

  return (
    <>
      {output}
      {!!text && (
        <Portal
          duration={250}
          ref={portalRef}
          transitionName="tooltip"
          Junction={Arrow}
          backdrop={false}
          isOpen={isOpen}
          className={clsx({'pointer-events-none': !isReactElement})}
          anchorEl={childRef || ref}
          onMouseLeave={hideTooltipRef.current}
          zIndex={level + Level.Message}
          isTooltip
          {...positionToOrigin(position)}
        >
          {renderContent()}
        </Portal>
      )}
    </>
  );
};
