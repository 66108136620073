import clsx from 'clsx';
import {FC, isValidElement, PropsWithChildren, ReactElement, RefObject, useEffect, useRef, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import {Subject, useSubject} from '../../external/helpers';
import {TestableElement} from '../../external/types';
import {Label, LabelProps, LabelSize} from '../../internal/components/Label/Label';
import {Icon, IconSize, IconSvg} from '../Icon/Icon';
import {Notification, NotificationSize, NotificationVariant} from '../Notification/Notification';
import {
  DropdownList,
  DropdownListOptions,
  DropdownListOptionValue,
  DropdownListProps,
  DropdownListWidth,
  IconPolicy,
} from './DropdownList';

export enum DropdownSize {
  SM = 'SM',
  MD = 'MD',
}

export enum DropdownWidth {
  FULL = 'FULL',
  FIT = 'FIT',
  BASE = 'BASE',
  INITIAL = 'INITIAL',
}

export type DropdownProps = {
  options: DropdownListOptions;
  value?: DropdownListOptionValue[];
  defaultValue?: DropdownListOptionValue[];
  onChange?: (value: DropdownListOptionValue[]) => void;
  className?: string;
  width?: DropdownWidth;
  size?: DropdownSize;
  dropdownWidth?: DropdownListWidth;
  dropdownClassName?: string;
  activeOptionClassName?: string;
  inputClassName?: string;
  dropdownMaxHeight?: number;
  dropUp?: boolean;
  isError?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  label?: string | ReactElement<LabelProps>;
  notification?: string;
  useSearch?: boolean;
  placeholder?: string;
  required?: boolean;
  groupsAlwaysOpened?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  maxLines?: DropdownListProps['maxLines'];
  isOpenSubject?: Subject<boolean>;
} & TestableElement;

export const Dropdown: FC<PropsWithChildren<DropdownProps & {componentRef?: RefObject<HTMLDivElement>}>> = ({
  options = [],
  value = [],
  defaultValue = undefined,
  onChange = () => undefined,
  className = undefined,
  width = DropdownWidth.BASE,
  size = DropdownSize.MD,
  dropdownWidth = DropdownListWidth.AS_ANCHOR,
  dropdownClassName = undefined,
  activeOptionClassName = undefined,
  inputClassName = undefined,
  dropdownMaxHeight = 340,
  dropUp = false,
  isError = false,
  disabled = false,
  multiple = false,
  label = '',
  notification = '',
  useSearch = false,
  children = undefined,
  placeholder = undefined,
  required = false,
  groupsAlwaysOpened = false,
  onBlur = undefined,
  onFocus = undefined,
  testId = undefined,
  maxLines = 2,
  isOpenSubject = undefined,
  componentRef = undefined,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const suppressBlur = useRef<boolean>(false);
  const [isOpen, _setOpen] = useState<boolean>(false);
  const setOpen = (nextIsOpen: boolean): void => {
    suppressBlur.current = nextIsOpen;
    _setOpen(nextIsOpen);
    if (nextIsOpen === false) {
      if (onBlur) {
        onBlur();
      }
    }
  };

  useSubject<boolean>(isOpenSubject, (nextIsOpen: boolean) => setOpen(nextIsOpen));

  const [selectedValues, setSelectedValues] = useState<DropdownListOptionValue[]>(defaultValue || value);
  const placeholderRef = useRef<HTMLSpanElement>(null);
  const [displayTitle, setDisplayTitle] = useState(false);

  const getValues = () => (defaultValue ? selectedValues : value);

  const selectedOptions = options.filter(option => getValues().includes(option.value));

  const placeholderOrValue =
    placeholder && selectedOptions.length === 0 ? placeholder : selectedOptions.map(option => option.label).join(', ');

  useEffect(() => {
    setDisplayTitle(
      placeholderRef.current ? placeholderRef.current.scrollWidth > placeholderRef.current.offsetWidth : false,
    );
  }, [selectedValues, placeholderOrValue]);

  const styles = {
    component: clsx('inline-block', {
      'w-full': width === DropdownWidth.FULL,
      'w-[285px]': width === DropdownWidth.BASE,
      'w-fit': width === DropdownWidth.FIT,
    }),
    input: clsx(
      'inline-flex w-full h-full before:rounded-lg relative before:absolute before:w-[calc(100%+2px)] before:h-[calc(100%+2px)] before:ml-[-17px]',
      {
        'h-[40px]': size === DropdownSize.SM,
        'h-[48px]': size === DropdownSize.MD,
        'border rounded-lg px-[16px] flex items-center text-base font-quicksand': true,
        'border-grey-300 text-grey-300': disabled,
        'before:focus-visible:outline before:focus-visible:outline-[2px] before:focus-visible:outline-white focus-visible:outline focus-visible:outline-[3px] focus-visible:outline-primary-700':
          !disabled,
        'border-red-500 text-black': !disabled && isError,
        'border-grey-300 hover:border-primary-500 text-black': !disabled && !isError,
      },
    ),
    label: twMerge(
      clsx('mb-[8px]', {
        'text-grey-400': disabled,
      }),
    ),
    icon: clsx({
      'filter-grey-300': disabled,
      'filter-red-500': !disabled && isError,
      'filter-primary-500': !disabled && !isError,
    }),
    contentBox: clsx('w-full overflow-hidden text-ellipsis whitespace-nowrap', {
      'text-grey-500': placeholder && selectedOptions.length === 0,
    }),
  };

  const handleChange = (nextValue: DropdownListOptionValue[]): void => {
    if (defaultValue) {
      setSelectedValues(nextValue);
    }
    onChange(nextValue);
    if (!multiple) {
      setOpen(false);
    }
  };

  const handleKeyDown = (keyCode: string) => {
    if (keyCode === 'Enter' || keyCode === 'Space') {
      setOpen(true);
    }
  };

  useEffect(() => {
    const handleFocus = () => {
      if (onFocus) {
        onFocus();
      }
    };
    ref.current?.addEventListener('focusin', handleFocus);
    return () => {
      ref.current?.removeEventListener('focusin', handleFocus);
    };
  }, [onFocus]);

  useEffect(() => {
    const handleBlur = () => {
      if (onBlur && !suppressBlur.current) {
        onBlur();
      }
    };

    ref.current?.addEventListener('focusout', handleBlur);
    return () => {
      ref.current?.removeEventListener('focusout', handleBlur);
    };
  }, [onBlur]);

  const render = () => (
    <div
      onKeyDown={event => handleKeyDown(event.code)}
      ref={ref}
      tabIndex={0}
      className={twMerge(clsx(styles.input, inputClassName))}
      onClick={() => !disabled && setOpen(!isOpen)}
    >
      <span className={styles.contentBox} ref={placeholderRef}>
        {placeholderOrValue}
      </span>
      <Icon
        className={styles.icon}
        svg={isOpen ? IconSvg.KEYBOARD_ARROW_UP : IconSvg.KEYBOARD_ARROW_DOWN}
        size={IconSize.LG}
      />
    </div>
  );

  const handleClose = () => {
    setOpen(false);
  };

  const renderList = () => (
    <DropdownList
      isOpen={isOpen}
      activeOptionClassName={activeOptionClassName}
      onClose={handleClose}
      onChange={handleChange}
      className={clsx(dropdownClassName, `max-h-[${dropdownMaxHeight}px]`, {'mt-[8px]': !dropUp, 'mb-[8px]': dropUp})}
      options={options}
      value={getValues()}
      anchorEl={ref}
      useSearch={useSearch}
      multiple={multiple}
      width={dropdownWidth}
      dropUp={dropUp}
      maxLines={maxLines}
      groupsAlwaysOpened={groupsAlwaysOpened}
      iconPolicy={IconPolicy.SHOW_ON_HOVER}
    >
      {children}
    </DropdownList>
  );

  const renderNotification = () => (
    <Notification
      text={notification}
      variant={isError ? NotificationVariant.ERROR : NotificationVariant.INFO}
      size={NotificationSize.SM}
      className="mt-[8px]"
    />
  );

  return (
    <span
      title={displayTitle ? placeholderOrValue : undefined}
      ref={componentRef}
      data-test-element="dropdown"
      data-testid={testId}
      className={twMerge(clsx(styles.component, className))}
    >
      {label &&
        (isValidElement(label) ? (
          label
        ) : (
          <Label
            className={styles.label}
            text={label}
            isRequired={required}
            size={size === DropdownSize.MD ? LabelSize.MD : LabelSize.SM}
          />
        ))}
      {render()}
      {renderList()}
      {notification && renderNotification()}
    </span>
  );
};
